<template lang="html">
    <div class="docManagment">
        <div>
            <p class="m-2"></p>
            <common-tabs-component
                :tabList="tabList"
                @tabChanged="tabChanged"
            />
            <div v-if="selectedTabName === 'Pick a file from File Manager'">
                <file-manager
                    ref="fm"
                    class="m-browse"
                    @fileSelectInFM="onFileSelect"
                    :fileManagerHeight="'calc(100vh - 445px)'"
                />
            </div>
            <div v-else>
                <div class="container fileUpload-container">
                    <div>
                        <div class="row mt-3">
                            <div cols="auto" class="col mr-auto">
                                <span>Select document</span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col" id="droparea" v-cloak>
                                <div class="file-upload-outer-wrapper">
                                    <b-icon-cloud-arrow-up
                                        class="uploadicon"
                                    ></b-icon-cloud-arrow-up>
                                    <span>Drag and drop file to upload</span>
                                    <span>Or</span>
                                    <span>
                                        <ejs-uploader
                                            ref="uploadObj"
                                            id="defaultfileupload"
                                            :selected="onSelect"
                                            name="UploadFiles"
                                            :autoUpload="autoUpload"
                                            :dropArea="dropElement"
                                            :removing="onFileRemove"
                                            :fileListRendering="fileListHandler"
                                            :allowedExtensions="extensions"
                                            :multiple="multiple"
                                        ></ejs-uploader>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <span class="directory-container mx-auto py-2 px-4">
                <p class="mx-2 my-2">
                    Select directory to store processed data:
                </p>
                <CustomFloatingLabel
                    :config="{
                        label: 'Directory',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                    class="directoryLbl mb-0 mx-2"
                >
                    <b-form-select
                        class="directorySelect mb-0"
                        v-model="processFileStoragePath"
                    >
                        <option
                            v-for="d in directories"
                            :value="d.DirectoryID"
                            :key="d.DirectoryID"
                        >
                            {{ d.dirFullName }}
                        </option>
                    </b-form-select>
                </CustomFloatingLabel>
            </span>

            <div class="bottom-button-container">
                <b-button
                    rounded
                    class="buttonSelect "
                    @click="submitToApi"
                    :disabled="uploadReady"
                >
                    Process file
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { projectService } from '../../services/project.service';
import { directoryService } from '../../services/directory.service';
import { datasetService } from '../../services/dataset.service';
import { mapMutations } from 'vuex';
import { commonService } from '../../services';
import { mapGetters } from 'vuex';

import CommonTabsComponent from '../CommonComponents/CommonTabsComponent.vue';
import FileManager from '../CommonComponents/FileManager.vue';
import CustomFloatingLabel from '../CustomFloatingLabel.vue';

export default {
    name: 'AutotablesUpload',
    components: {
        FileManager,
        CustomFloatingLabel,
        CommonTabsComponent,
    },
    props: {
        showdocument: Boolean,
    },
    data() {
        return {
            picked: '',
            selectedGames: [],
            selected: '',
            mydata: [],
            nextscreen: false,
            saveButton: false,
            uploadedFiles: [],
            processFileStoragePath: '',
            validateUpload: true,
            autoUpload: false,
            attributes: '',
            documentTabopen: false,
            directories: [],
            hideSearch: true,
            showSuccess: false,
            showFailure: false,
            showConnecting: false,
            failureToastMessage: null,
            connectingToastMessage: null,
            successToastMessage: null,
            showPage: false,
            projectId: null,
            attValues: [],
            selectedRadio: 0,
            checkedBox: [],
            dropElement: '#droparea',
            selectedFileInFileManager: {},
            selectedTabName: 'Pick a file from File Manager',
            tabList: [
                { name: 'Pick a file from File Manager' },
                { name: 'Upload a file' },
            ],
            extensions: '.xlsx, .xls',
            multiple: false,
            queueService: 'Auto table',
        };
    },
    created() {
        this.getDirectories();
        this.setToastMessage({
            queueService: this.queueService,
            queueState: '',
            queueMessage: '',
            downloadLink: '',
            visible: false,
        });
    },
    computed: {
        uploadReady() {
            if (
                this.processFileStoragePath &&
                (this.uploadedFiles.length == 1 ||
                    Boolean(this.selectedFileInFileManager.isFile))
            ) {
                return false;
            } else {
                return true;
            }
        },
        ...mapGetters('projects', {
            getSelectedProject: 'getSelectedProject',
        }),
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        tabChanged(newTab, prevTab) {
            this.selectedTabName = newTab.name;
        },

        async submitToApi() {
            let payload = this.getParameters();
            try {
                const response = await directoryService.autotableUpload(
                    payload
                );
                const jobId = response.data.Data;
                await this.checkStatusUntilComplete(jobId);
            } catch (err) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: 'failed!',
                    visible: true,
                    downloadLink: '',
                });
            }
        },
        getParameters() {
            let payload = new FormData();
            if (this.selectedTabName == 'Pick a file from File Manager') {
                //get user selected file path and file name in File Manager as a string
                if (this.selectedFileInFileManager) {
                    payload.append(
                        'fileManagerPath',
                        this.selectedFileInFileManager.filterPath +
                            this.selectedFileInFileManager.name
                    );
                }
            } else if (this.selectedTabName == 'Upload a file') {
                //get user file upload
                if (this.uploadedFiles.length > 0) {
                    payload.append('file', this.uploadedFiles[0].rawFile);
                }
            }
            //get target directory ID
            payload.append('directoryId', this.processFileStoragePath);
            //get project id
            payload.append(
                'projectID',
                this.$store.state.projects.selectedProjects[0].ProjectID
            );
            return payload;
        },
        async checkStatusUntilComplete(jobId) {
            let continueLoop = true;

            try {
                while (continueLoop) {
                    const statusResponse = await datasetService.getStatus(
                        jobId
                    );
                    const status = statusResponse.data;
                    switch (status) {
                        case 'Completed':
                            var response = await datasetService.getQueue(jobId);
                            var functionReturnStatus = JSON.parse(
                                response.data.FunctionReturnStatus
                            );
                            var docId = +functionReturnStatus.Data;
                            var docHref = await this.downloadFile(docId);
                            var fileName = await this.getFileName(docId);
                            var link = await commonService.getDownloadLink(
                                docHref,
                                fileName
                            );
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'complete',
                                queueMessage: 'completed!',
                                downloadLink:
                                    '<a href="' +
                                    link +
                                    '" download="' +
                                    fileName +
                                    '" > Download ' +
                                    fileName +
                                    ' </a>',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                        case 'Error':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                downloadLink: '',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                        case 'Unkown JobID':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                downloadLink: '',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                        case 'Queued':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'queue',
                                queueMessage: 'in queue!',
                                downloadLink: '',
                                visible: true,
                            });
                            break;
                        case 'Processing':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                downloadLink: '',
                                visible: true,
                            });
                            break;
                        default:
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: status,
                                downloadLink: '',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 5000));
                }
            } catch (error) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: 'failed!' + error,
                    visible: true,
                    downloadLink: '',
                });
            }
        },
        onFileSelect: function(args) {
            if (args.action == 'select') {
                this.selectedFileInFileManager = args.fileDetails;
            } else {
                this.selectedFileInFileManager = {};
            }
        },
        onFileRemove: function(args) {
            args.postRawFile = false;
            this.uploadedFiles.splice(
                this.uploadedFiles.indexOf(args.filesData[0]),
                1
            );
        },

        fileListHandler: function(args) {
            if (args.fileInfo.size < 1000) {
                args.element.getElementsByClassName(
                    'e-file-size'
                )[0].innerText = args.fileInfo.size / 1000 + ' KB';
            }
        },
        async downloadFile(docId) {
            return await commonService.getDocumentLinkByID(docId);
        },
        async getFileName(docId) {
            var response = await commonService.getDocument(docId);
            return response.data.FileName;
        },
        onSelect(args) {
            // check whether the file is uploading from paste.
            this.nextscreen = false;
            this.saveButton = false;
            for (let i = 0; i < args.filesData.length; i++) {
                args.filesData[i]['description'] = '';
                args.filesData[i]['keyword'] = '';
                args.filesData[i]['directory'] = '';
                args.filesData[i]['AttributeName'] = '';
                args.filesData[i]['attributes'] = [];
                this.uploadedFiles.push(args.filesData[i]);
            }
            // this.uploadedFiles = args.filesData;
            if (this.uploadedFiles) {
                this.validateUpload = false;
            } else {
                this.validateUpload = true;
            }
        },
        getDirectories() {
            this.projectId = this.$store.state.projects.selectedProjects[0].ProjectID;
            projectService
                .getListofProjectDirectories(this.projectId)
                .then((res) => {
                    this.directories = res.data
                        .map((dir) => {
                            let fName = dir.BlobPath + dir.DirectoryName;
                            let dirfName = fName.replace(
                                this.projectId + '/',
                                ''
                            );
                            dir['dirFullName'] = dirfName;
                            return dir;
                        })
                        .sort((a, b) =>
                            a.dirFullName.localeCompare(b.dirFullName)
                        );
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.text-right.icons {
    //margin-top: -10px;
    max-width: 100px;
}

.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;

    p {
        text-align: center;

        width: 248px;
        margin-left: 128px;
        font-size: 18px;
        color: #000;

        a {
            font-size: 20px;
            color: #006395 !important;
        }
    }
}

.uploadicon {
    height: 56px;
    width: 82px;
}

.iconplacing {
    //padding-left: 200px;
    padding-top: 13px;
}

.autoTableButton {
    position: absolute;
}

#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
}

#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #626262;
    padding-left: 20px;
}

button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../../assets/minus_sign_big.svg') no-repeat right
        0.75rem center !important;
}

.dragdrop {
    margin-left: 140px;
    text-align: center;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000;
}

//listing file in toast message after document upload
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}

button:disabled,
button[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}

input:disabled,
input[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}

div#__BVID__62___BV_modal_backdrop_ {
    opacity: 1 !important;
    background-color: #8a8a8a !important;
}

#file-manager {
    display: block !important;
    border: 1px solid red !important;
}

#file-manager_layout {
    display: block !important;
}

#outersearchinput {
    height: 40px !important;
    padding-top: 5px;
}

.directoryLbl,
.directorySelect {
    display: block;
}
.docManagment {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: calc(100vh - 126px);
}
.bottom-button-container {
    height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.directory-container {
    height: 120px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.file-upload-outer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 195px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
}

button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../../assets/minus_sign_big.svg') no-repeat right
        0.75rem center !important;
}
.btn-info {
    color: #000 !important;
    height: 50px;
    text-align: left !important;
    font-size: 20px;
    font-weight: 500;
    border: none !important;
    background: #eaeaea url('../../assets/big_plus.svg') no-repeat right 0.75rem
        center !important;
}
.fileUpload-container {
    height: calc(100vh - 461px);
}
</style>
<style lang="scss">
.docManagment {
    .e-upload.e-control-wrapper,
    .e-bigger.e-small .e-upload.e-control-wrapper {
        width: 100%;
    }
    .e-upload-file-list {
        width: 100% !important;
        border: 1px solid grey !important;
        border-radius: 5px;
        height: 50px !important;
    }

    .e-upload .e-upload-files {
        min-width: 250px;
        max-width: 350px !important;
    }
    .e-file-container {
        top: -6px !important;
    }
    .e-file-name {
        max-width: 150px !important;
    }
    .e-upload-files {
        border: none !important;
    }
}
</style>
