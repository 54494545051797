<!-- 
    *This component can use to add tabs for side panel components
    *mouse wheel can be used to scroll horizontally
    :prop - tabList [...{...name}] - a string array with tab headings
    @emit - tabChanged - emit when changing the tabs
        @params - newTabName and prevTabName
 -->
<template>
    <div class="main-container">
        <div v-if="isScrollBtnVisible" class="scroll_btn" @click="rightScroll">
            <b-icon
                class="scroll_btn_icon"
                icon="play-fill"
                aria-hidden="true"
                rotate="180"
            ></b-icon>
        </div>
        <b-container
            fluid
            class="direction-cls wrapper-class"
            @mousewheel="onWheel($event)"
            :id="uniqContainerID"
        >
            <b-tabs
                v-if="closeableTabs"
                content-class="mt-3 content-class"
                nav-class="navClass"
                nav-wrapper-class="nav-wrapper-class"
                @activate-tab="activateTab"
                v-model="activeTabIndex"
                :id="uniqTabContainerID"
            >
                <b-tab
                    title-item-class="title-item-class"
                    title-link-class="title-link-class title-closable"
                    v-for="(tab, index) in tabList"
                    :key="index"
                >
                    <template #title>
                        <p>{{ tab.name }}</p>

                        <b-button
                            @click="closeTab(tab.name)"
                            variant="light"
                            class="closeTab-img p-1 mx-1"
                        >
                            <img src="../../assets/close_icon.svg" />
                        </b-button>
                    </template>

                    <b-spinner type="grow" small></b-spinner>
                </b-tab>
                <template #tabs-end>
                    <slot name="tabs-end"></slot>
                </template>
            </b-tabs>
            <b-tabs
                v-else
                content-class="mt-3 content-class"
                nav-class="navClass"
                nav-wrapper-class="nav-wrapper-class"
                @activate-tab="activateTab"
                v-model="activeTabIndex"
                :id="uniqTabContainerID"
            >
                <b-tab
                    :title="tab.name"
                    title-item-class="title-item-class"
                    title-link-class="title-link-class"
                    v-for="(tab, index) in tabList"
                    :key="index"
                >
                    <b-spinner type="grow" small></b-spinner>
                </b-tab>
            </b-tabs>
        </b-container>
        <div v-if="isScrollBtnVisible" class="scroll_btn" @click="leftScroll">
            <b-icon
                class="scroll_btn_icon"
                icon="play-fill"
                aria-hidden="true"
            ></b-icon>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
    props: {
        tabList: Array,
        alwaysSelectLast: {
            type: Boolean,
            default: false,
        },
        activeTab: String,
        closeableTabs: Boolean,
    },
    data() {
        return {
            activeTabIndex: 0,
            uniqContainerID: uuidv4(),
            uniqTabContainerID: uuidv4(),
            isScrollBtnVisible: false,
        };
    },
    methods: {
        onWheel(e) {
            e.preventDefault();
            const container = document.getElementById(
                `${this.uniqContainerID}`
            );
            const containerScrollPosition = document.getElementById(
                `${this.uniqContainerID}`
            ).scrollLeft;
            container.scrollTo({
                top: 0,
                left: containerScrollPosition + e.deltaY,
                behaviour: 'smooth',
            });
        },
        activateTab(newTabIndex, prevTabIndex, bvEvent) {
            this.$emit(
                'tabChanged',
                this.tabList[newTabIndex],
                this.tabList[prevTabIndex]
            );
        },
        leftScroll() {
            document.getElementById(this.uniqContainerID).scrollLeft += 50;
        },
        rightScroll() {
            document.getElementById(this.uniqContainerID).scrollLeft -= 50;
        },
        // can use a ref in a parent to update the visible tab
        setActiveTabIndex(index) {
            this.activeTabIndex = index;
        },
        closeTab(tabName) {
            this.$emit('closeTab', tabName);
        },
    },
    computed: {},
    mounted() {},
    watch: {
        tabList() {
            if (this.alwaysSelectLast) {
                // need a very small delay here, can get away with 10ms
                setTimeout(() => {
                    this.activeTabIndex = this.tabList.length;
                }, 50);
            } else {
                this.activeTabIndex = this.tabList.findIndex(
                    (tab) => tab.name === this.activeTab
                );
            }
            this.$nextTick(() => {
                let ele = document.getElementById(`${this.uniqTabContainerID}`);
                this.isScrollBtnVisible =
                    ele.scrollWidth > ele.clientWidth ? true : false;
            });
        },
        activeTab(newTab) {
            this.activeTabIndex = this.tabList.findIndex(
                (tab) => tab.name === newTab
            );
        },
    },
};
</script>

<style>
.main-container {
    display: flex;
    flex-direction: row;
}
.scroll_btn {
    width: 20px;
    height: 59px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.scroll_btn_icon {
    user-select: none;
}
.wrapper-class {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    z-index: 10;
    height: 59px;
    overflow: hidden;
}
.direction-cls {
    direction: ltr;
}
.navClass {
    flex-direction: row;
    flex-wrap: nowrap !important;
    direction: unset !important;
}
.title-item-class {
    flex: none;
    padding: 10px !important;
}
.title-link-class {
    color: #1d1d1d !important;
    padding: 0px !important;
    height: 39px;
    margin-top: 10px !important;
}
.nav-wrapper-class {
    display: flex;
}
.title-closable {
    display: flex;
    flex-flow: row wrap;
}
.closeTab-img {
    width: 30px;
    height: 30px;
}
.closeTab-img img {
    vertical-align: text-top;
}
</style>
